import {Injectable} from '@angular/core'
import {cloneDeep} from 'lodash-es'
import {TreoNavigationItem} from '@treo/components/navigation'
import {TreoMockApi} from '@treo/lib/mock-api/mock-api.interfaces'
import {TreoMockApiService} from '@treo/lib/mock-api/mock-api.service'
import {
    compactNavigation,
    defaultNavigation,
    futuristicNavigation,
    horizontalNavigation
} from 'app/data/mock/common/navigation/data'

/**
 * @class       NavigationMockApi
 * @implements  TreoMockApi
 * @description Load navigation data (left menu)
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi implements TreoMockApi {
    // Private Readonly
    private readonly _compactNavigation: TreoNavigationItem[]
    private readonly _defaultNavigation: TreoNavigationItem[]
    private readonly _futuristicNavigation: TreoNavigationItem[]
    private readonly _horizontalNavigation: TreoNavigationItem[]

    /**
     * Constructor
     *
     * @param _treoMockApiService
     */
    constructor(
        private _treoMockApiService: TreoMockApiService
    ) {
        // Set the data
        this._compactNavigation = compactNavigation
        this._defaultNavigation = defaultNavigation
        this._futuristicNavigation = futuristicNavigation
        this._horizontalNavigation = horizontalNavigation

        // Register the API endpoints
        this.register()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register
     */
    register(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._treoMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children)
                        }
                    })
                })

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === futuristicNavItem.id) {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children)
                        }
                    })
                })

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === horizontalNavItem.id) {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children)
                        }
                    })
                })

                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ]
            })
    }
}
