import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {switchMap} from 'rxjs/operators'
import jwt_decode from 'jwt-decode'
import {AccountGroup, AccountModule, SuccessResponse, TokenPayload} from 'app/shared/shared.types'
import {AuthCredentials, AuthStatus, AuthTokens, SetPassword} from 'app/core/auth/auth.types'
import {Account} from 'app/modules/admin/account/account.types'
import {AuthUtils} from 'app/core/auth/auth.utils'
import {environment} from '../../../environments/environment'

/**
 * @class       AuthService
 * @summary     Authentication and authorization service for user and customer accounts
 *
 * @description Sign up, sign in and sign out
 *              Send reset password link and set password
 *              Get, set and refresh auth tokens
 *              Check account permissions (acl) for auth guard
 */
@Injectable()
export class AuthService {
    /**
     * Token payload
     *
     * @private
     */
    private _tokenPayload: BehaviorSubject<TokenPayload | null>

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient - Http client module for executing http requests
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this._tokenPayload = new BehaviorSubject(null)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter for access token
     *
     * @param {string} token - Access token used for authorizing requests
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token)
    }

    /**
     * Getter for access token
     */
    get accessToken(): string {
        return localStorage.getItem('accessToken')
    }

    /**
     * Setter for refresh token
     *
     * @param {string} token - Refresh token used for renewing auth tokens
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token)
    }

    /**
     * Getter for refresh token
     */
    get refreshToken(): string {
        return localStorage.getItem('refreshToken')
    }

    /**
     * Getter for token payload
     */
    get tokenPayload$(): Observable<TokenPayload> {
        return this._tokenPayload.asObservable()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set auth tokens and token payload
     *
     * @param {AuthTokens} authTokens - Access and refresh tokens
     * @private
     */
    private _setAuthTokens(authTokens: AuthTokens): void {
        // Store the access and refresh tokens in the local storage
        this.accessToken = authTokens.accessToken
        this.refreshToken = authTokens.refreshToken

        // Set token payload
        this.setTokenPayload()
    }

    /**
     * Check account permissions
     *
     * @param {AccountModule} module - Account module name to be checked
     * @return {boolean} - Whether signed in account has permissions or not
     * @private
     */
    private _checkAccountPermissions(module?: AccountModule): boolean {
        const payload = this.getTokenPayload()

        // Check account status
        if (payload.status !== 'activated') {
            return false
        }

        // Check acl for caller module
        if (module) {
            if (!(module in payload.acl)) {
                return false
            }
            if (!payload.acl[module].read) {
                return false
            }
        }

        return true
    }

    /**
     * Refresh auth tokens
     *
     * @param {AccountModule} module - Account module name to be checked after refreshing tokens
     * @param {AccountGroup} accountGroup - Account group to refresh the tokens for
     * @return {Observable<AuthStatus>} - Authentication and authorization status of the refreshed tokens
     * @private
     */
    _refreshTokens(module: AccountModule = null, accountGroup: AccountGroup = 'customer'): Observable<AuthStatus> {
        // Current access and refresh tokens
        const body = {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
        }

        // Renew tokens
        return this._httpClient.post(environment.apiUrl + '/auth/refresh-token/' + accountGroup, body).pipe(
            switchMap((authTokens: AuthTokens) => {

                // Set auth tokens
                this._setAuthTokens(authTokens)

                // Check account permissions
                if (!this._checkAccountPermissions(module)) {
                    return of({authenticated: true, authorized: false})
                }

                // Allow access
                return of({authenticated: true, authorized: true})
            })
        )
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set token payload
     */
    setTokenPayload(): void {
        const payload = this.getTokenPayload()
        this._tokenPayload.next(payload)
    }

    /**
     * Get token payload
     */
    getTokenPayload(): TokenPayload {
        return jwt_decode<TokenPayload>(this.accessToken)
    }

    /**
     * Sign up
     *
     * @param {AccountGroup} account - Account data to be signed up
     * @return {Observable<SuccessResponse>} - Sign in success response
     */
    signUp(account: Account): Observable<SuccessResponse> {
        return this._httpClient.post<SuccessResponse>(environment.apiUrl + '/auth/sign-up', account).pipe(
            switchMap((response: SuccessResponse) => {

                // Return a new observable with the response
                return of(response)
            })
        )
    }

    /**
     * Sign in
     *
     * @param {AuthCredentials} credentials - Credentials to sign in
     * @param {AccountGroup} accountGroup - Account group to sign in for
     * @return {Observable<AuthTokens>} - Auth tokens from sign in response
     */
    signIn(credentials: AuthCredentials, accountGroup: AccountGroup): Observable<AuthTokens> {
        return this._httpClient.post<AuthTokens>(environment.apiUrl + '/auth/sign-in/' + accountGroup, credentials).pipe(
            switchMap((authTokens: AuthTokens) => {
                // Set auth tokens
                this._setAuthTokens(authTokens)

                // Return a new observable with the auth tokens
                return of(authTokens)
            })
        )
    }

    /**
     * Sign out
     */
    signOut(): void {
        // Remove the auth tokens from the local storage
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
    }

    /**
     * Send reset password link
     *
     * @param {string} email - Email address to reset the password
     * @param {AccountGroup} accountGroup - Account group to reset the password
     * @return {Observable<SuccessResponse>} - Send reset link success response
     */
    sendResetLink(email: string, accountGroup: AccountGroup): Observable<SuccessResponse> {
        // Email address
        const body = {
            email: email
        }

        // Send request
        return this._httpClient.post(environment.apiUrl + '/auth/request-pass/' + accountGroup, body).pipe(
            switchMap((response: SuccessResponse) => {

                // Return a new observable with the response
                return of(response)
            })
        )
    }

    /**
     * Set password
     *
     * @param {SetPassword} setPasswordData - Data required for setting a new password
     * @param {AccountGroup} accountGroup - Account group for setting a new password
     * @return {Observable<SuccessResponse>} - Set password success response
     */
    setPassword(setPasswordData: SetPassword, accountGroup: AccountGroup = 'customer'): Observable<SuccessResponse> {
        return this._httpClient.post(environment.apiUrl + '/auth/set-pass/' + accountGroup, setPasswordData).pipe(
            switchMap((response: SuccessResponse) => {

                // Return a new observable with the response
                return of(response)
            })
        )
    }

    /**
     * Check the auth status for the signed in account
     * Called from authGuard and noAuthGuard
     *
     * @param {AccountModule} module - Account module name to be checked
     * @return {Observable<AuthStatus>} - Authentication and authorization status
     */
    check(module?: AccountModule): Observable<AuthStatus> {
        // Check the access token availability
        if (!this.accessToken) {
            return of({authenticated: false, authorized: false})
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of({authenticated: false, authorized: false})
        }

        // Check account permissions
        if (!this._checkAccountPermissions(module)) {
            return of({authenticated: true, authorized: false})
        }

        return this._refreshTokens(module, this.getTokenPayload().group)
    }


    /**
    * Verifies customer's email address
    *
    * @param {email} email - Customer's email address
    * @return {Observable<any>} - Return information about user's account
    */
    verifyEmail(email: string): Observable<any> {
        return this._httpClient.get<any>(environment.apiUrl + '/auth/check/' + email).pipe(
            switchMap((val) => {
                return of(val)
            })
        )
    }
}
