import {Injectable} from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router'
import {Observable, of} from 'rxjs'
import {AuthService} from 'app/core/auth/auth.service'
import {switchMap} from 'rxjs/operators'
import {AuthStatus} from "../auth.types";

/**
 * @class       NoAuthGuard
 * @implements  {CanActivate, CanActivateChild}
 * @summary     Authentication and authorization guard for all non-admin routes/modules
 *
 * @description Check if the current account is authenticated and authorized
 *              Allow or prevent user from accessing the module
 *              Redirect user depending on auth status
 */
@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param {Router} _router
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     * Allow or prevent access from user to module
     *
     * @private
     */
    private _check(): Observable<boolean> {
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((status: AuthStatus) => {
                    // If the user is authenticated
                    if (status.authenticated) {
                        // Redirect to the root
                        this._router.navigate(['/'])

                        // Prevent the access
                        return of(false)
                    }

                    // Allow the access
                    return of(true)
                })
            )
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param {ActivatedRouteSnapshot} route - Activated route
     * @param {RouterStateSnapshot} state - Router state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check()
    }

    /**
     * Can activate child
     *
     * @param {ActivatedRouteSnapshot} childRoute - Activated child route
     * @param {RouterStateSnapshot} state - Router state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check()
    }

}
