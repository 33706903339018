export const environment = {
    production: true,
    // URL for the main server API
    // apiUrl: 'http://85.214.136.130:3001/v1',
    apiUrl: 'https://api.account.canify.com/v1',
    // apiUrl: 'https://api.account.canify.tekr.io/v1',

    // API key to gain access to the API
    apiKey: '2v4sWvqwqzbvAPnHNMv7e8tMKf35YDY2QcuVKUsjxxyQHvmqkyAH2Fc25AjfyScV',
    // Signature configs
    signature: {
        fileName: 'Signature',
        fileExtension: '.png',
        // Access key to show signature file
        accessKey: 'AJmeY5vB745hZFygvBGjthSxzDaPyrRp'
    },
    // Shop URL
    shopUrl: 'https://shop.canify.com',
    docCheckCode: '2000000020639',
    // shopUrl: 'https://shop.canify.tekr.io',
    adminUser: 'admin@tekr.io',
    adminPass: '9N8j1ZhWtuTtsK51cn9Rck8VBj6Wa9yt'
}
