<!-- Navigation -->
<treo-vertical-navigation class="theme-dark"
                          [appearance]="'classic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">
    <div treoVerticalNavigationContentHeader>
        <div class="logo cursor-pointer" [routerLink]="'./'">
            <img src="assets/images/logo/logo-full-on-dark.png">
        </div>
    </div>
</treo-vertical-navigation>
<!-- Wrapper -->
<div class="wrapper">
    <!-- Header -->
    <div class="header">
        <!-- Navigation toggle button -->
        <button mat-icon-button class="navigation-toggle-button" (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>
        <!-- Spacer -->
        <div class="spacer"></div>
        <!-- User -->
        <user></user>
    </div>
    <!-- Content -->
    <div class="content">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
