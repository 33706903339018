import {HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {catchError, finalize} from 'rxjs/operators'
import {ErrorDialogService} from 'app/shared/dialogs/error-dialog/error-dialog.service'
import {Injectable} from '@angular/core'

/**
 * @class       HttpErrorInterceptor
 * @implements  HttpInterceptor
 * @summary     Error interceptor for handling HTTP errors
 *
 * @description Handle global errors and open error dialog
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    /**
     * Constructor
     *
     * @param {ErrorDialogService} errorDialogService - Error dialog service for displaying errors
     */
    constructor(private errorDialogService: ErrorDialogService) {
    }

    /**
     * Intercept http request
     *
     * @param {HttpRequest} request - HTTP request to be intercepted
     * @param {HttpHandler} next - HTTP handler
     * @return {Observable<HttpEvent>} - HTTP event as observable
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /***
         * Currently these errors are returned from the API:
         *
         * 400 Bad Request
         * 401 Unauthorized
         * 403 Forbidden
         * 404 Not Found
         * 500 Internal Server Error
         *
         * All error messages coming from the API correspond to translation paths on Angular
         * message is logged only in the API
         */
        return next.handle(request).pipe(
            catchError((response: HttpErrorResponse) => {
                let message = response.error?.message ?? response.message

                // Prevent showing default 500 Internal Server Error message
                if (response.status === 500) {
                    message = 'COMMON.GENERAL_ERROR'
                }

                // Show error dialog
                this.errorDialogService.openDialog(message)

                // Throw error
                return throwError(response)
            }),
            finalize(() => {
            })
        ) as Observable<HttpEvent<any>>
    }
}
