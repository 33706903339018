import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {TranslateModule} from '@ngx-translate/core'
import {RouterModule} from '@angular/router'
import {MaterialModule} from 'app/material.module'
import {SharedUtils} from 'app/shared/shared.utils'
import {ConfirmDialogComponent} from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component'
import {ErrorDialogComponent} from 'app/shared/dialogs/error-dialog/error-dialog.component'
import {ErrorDialogService} from 'app/shared/dialogs/error-dialog/error-dialog.service'
const sharedComponents = [ConfirmDialogComponent, ErrorDialogComponent]

/**
 * @class       SharedModule
 * @summary     Shared module
 *
 * @description Load shared modules and components
 */
@NgModule({
    declarations: [...sharedComponents],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        MaterialModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        MaterialModule,
        ...sharedComponents
    ],
    providers: [SharedUtils, ErrorDialogService],
    entryComponents: [...sharedComponents]
})
export class SharedModule {

}
