import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core'
import {Subject} from 'rxjs'

/**
 * @class       EmptyLayoutComponent
 * @implements  {OnInit, OnDestroy}
 * @summary     Layout component
 *
 * @description Treo empty layout
 */
@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {
    // Private
    private _unsubscribeAll: Subject<any>

    /**
     * Constructor
     */
    constructor() {
        // Set the private defaults
        this._unsubscribeAll = new Subject()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }
}
