import {NgModule} from '@angular/core'
import {UserComponent} from 'app/layout/common/user/user.component'
import {SharedModule} from 'app/shared/shared.module'
import {RouterModule} from '@angular/router'

/**
 * @class       UserModule
 * @description Load user component and shared modules
 */
@NgModule({
    declarations: [
        UserComponent
    ],
    imports: [
        SharedModule,
        RouterModule
    ],
    exports: [
        UserComponent
    ]
})
export class UserModule {
}
