import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {TranslateService} from '@ngx-translate/core'

/**
 * @class       ErrorDialogComponent
 * @summary     Error dialog component
 *
 * @description Show errors to users in a dialog
 */
@Component({
    selector: 'error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
    /**
     * Constructor
     *
     * @param {message: string} data - Dialog data
     * @param {TranslateService} translate - Translate service
     */
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {message: string},
        public translate: TranslateService,
    ) {
    }
}
