import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {AuthService} from 'app/core/auth/auth.service'
import {AuthUtils} from 'app/core/auth/auth.utils'
import {environment} from '../../../environments/environment'

/**
 * @class       AuthInterceptor
 * @implements  {HttpInterceptor}
 * @summary     Authorization interceptor
 *
 * @description Intercept HTTP requests
 *              Add auth header to requests that come from admin routes
 *              Handle 401 Unauthorized error
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param {AuthService} _authService - Authentication and authorization service
     */
    constructor(
        private _authService: AuthService
    ) {
    }

    /**
     * Intercept HTTP requests
     *
     * @param {HttpRequest} req - Request being sent
     * @param {HttpHandler} next - HTTP handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Inject API key in all HTTP requests
        const apiKey = 'Api-Key ' + environment.apiKey

        // We need to clone the request because HTTP headers are immutable
        // https://angular.io/api/common/http/HttpHeaders
        req = req.clone({
            headers: req.headers.set('X-Auth', apiKey)
        })

        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            // Authorization header to be injected in the request
            const bearerToken = 'Bearer ' + this._authService.accessToken

            // Add auth header to the existing headers
            req = req.clone({
                headers: req.headers.set('Authorization', bearerToken)
            })
        }

        // Response
        return next.handle(req).pipe(
            catchError((response) => {
                // Catch 401 responses
                if (response instanceof HttpErrorResponse && response.status === 401 &&
                    response?.error?.message !== 'AUTH.SIGN_IN.ERRORS.CREDENTIALS') { // skip sign-in error
                    // Sign out
                    this._authService.signOut()

                    // Reload the app
                    location.reload()
                }

                return throwError(response)
            })
        )
    }
}
