import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core'
import {DOCUMENT} from '@angular/common'
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router'
import {combineLatest, Subject} from 'rxjs'
import {filter, map, takeUntil} from 'rxjs/operators'
import {TreoConfigService} from '@treo/services/config'
import {TreoMediaWatcherService} from '@treo/services/media-watcher'
import {Layout} from 'app/layout/layout.types'
import {AppConfig} from 'app/core/config/app.config'
import {MatRadioChange} from '@angular/material/radio'

/**
 * @class       LayoutComponent
 * @implements  {OnInit, OnDestroy}
 * @summary     Layout component
 *
 * @description Treo layout
 */
@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
    config: AppConfig
    layout: Layout
    theme: 'dark' | 'light'

    // Private
    private _unsubscribeAll: Subject<any>

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {DOCUMENT} _document
     * @param {Router} _router
     * @param {TreoConfigService} _treoConfigService
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _treoConfigService: TreoConfigService,
        private _treoMediaWatcherService: TreoMediaWatcherService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the theme based on the configuration
        combineLatest([
            this._treoConfigService.config$,
            this._treoMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            map(([config, mql]) => {

                // If the config is set to 'dark' or 'light'...
                if (config.theme !== 'auto') {
                    return config.theme
                }

                // If the config is set to 'auto'...
                if (mql.breakpoints['(prefers-color-scheme: dark)'] === true) {
                    return 'dark'
                }

                return 'light'
            })
        ).subscribe((theme) => {

            // Store the theme
            this.theme = theme

            // Update the theme
            this._updateTheme()
        })

        // Subscribe to config changes
        this._treoConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {

                // Store the config
                this.config = config

                // Update the layout
                this._updateLayout()
            })

        // Subscribe to NavigationEnd event
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Update the layout
            this._updateLayout()
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute
        while (route.firstChild) {
            route = route.firstChild
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout') as Layout)
        if (layoutFromQueryParam) {
            this.config.layout = this.layout = layoutFromQueryParam
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot
        paths.forEach((path) => {

            // Check if there is a 'layout' data
            if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
                // Set the layout
                this.layout = path.routeConfig.data.layout
            }
        })
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className) => {
            if (className.startsWith('treo-theme-')) {
                this._document.body.classList.remove(className)
                return
            }
        })

        // Add class name for the currently selected theme
        this._document.body.classList.add('treo-theme-' + this.theme)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams: {
                layout: null
            },
            queryParamsHandling: 'merge'
        }).then(() => {

            // Set the config
            this._treoConfigService.config = {layout}
        })
    }

    /**
     * Set the theme on the config
     *
     * @param change
     */
    setTheme(change: MatRadioChange): void {
        this._treoConfigService.config = {theme: change.value}
    }
}
