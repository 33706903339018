import {Route} from '@angular/router'
import {AuthGuard} from 'app/core/auth/guards/auth.guard'
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard'
import {LayoutComponent} from 'app/layout/layout.component'
import {InitialDataResolver} from 'app/app.resolvers'

/**
 * App parent routes
 */
// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'pharmacy'},

    // Redirect signed in user to the '/example'
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'pharmacy'},

    // Auth routes (guest)
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'set-password',
                loadChildren: () => import('app/modules/auth/set-password/set-password.module').then(m => m.AuthSetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },

    // Auth routes (logged in)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
            },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'pharmacy',
                loadChildren: () => import('app/modules/admin/pharmacy/pharmacy.module').then(m => m.PharmacyModule)
            },
            {
                path: 'customer',
                loadChildren: () => import('app/modules/admin/customer/customer.module').then(m => m.CustomerModule)
            },
            {
                path: 'user',
                loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule)
            },
            {
                path: 'account',
                loadChildren: () => import('app/modules/admin/account/account.module').then(m => m.AccountModule)
            },
        ]
    }
]
