<!-- Title -->
<h4 matDialogTitle>{{title | translate}}</h4>
<!-- Content -->
<div mat-dialog-content>
    <form class="flex flex-col" [formGroup]="dialogForm" *ngIf="data">
        <!-- Optional date picker -->
        <mat-form-field *ngIf="data.dateLabel">
            <mat-label>{{data.dateLabel}}</mat-label>
            <input matInput [formControlName]="'date'" [min]="minDate" [matDatepicker]="datePicker"
                   [placeholder]="data.dateLabel">
            <mat-datepicker-toggle matSuffix [for]="datePicker">
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error translate [translateParams]="{label: data.dateLabel | translate}"
                       *ngIf="dialogForm.get('date').errors?.required">
                COMMON.ERRORS.REQ
            </mat-error>
            <mat-error translate [translateParams]="{label: data.dateLabel | translate}"
                       *ngIf="dialogForm.get('date').invalid && !dialogForm.get('date').errors?.required">
                COMMON.ERRORS.MIN_DATE
            </mat-error>
        </mat-form-field>
        <!-- Optional number of weeks to validate -->
        <mat-form-field class="treo-mat-textarea" *ngIf="data.textLabel">
            <mat-label>{{data.textLabel}}</mat-label>
            <textarea matInput treoAutogrow [formControlName]="'text'" [rows]="2" [maxLength]="250"
                      [placeholder]="data.textLabel"></textarea>
            <mat-error translate [translateParams]="{label: data.textLabel | translate}"
                       *ngIf="dialogForm.get('text').errors?.required">
                COMMON.ERRORS.REQ
            </mat-error>
            <mat-error translate [translateParams]="{label: data.textLabel | translate, minValue: 5}"
                       *ngIf="dialogForm.get('text').errors?.minlength">
                COMMON.ERRORS.MIN_CHAR
            </mat-error>
        </mat-form-field>
        <!-- Optional text area -->
        <mat-form-field *ngIf="data.weeksLabel">
            <mat-label>{{data.weeksLabel | translate}}</mat-label>
            <input matInput [formControlName]="'weeks'" [minLength]="1" [maxLength]="3"
                   [placeholder]="data.weeksLabel | translate"
                   (keypress)="allowInput($event, 'number')">
            <mat-error translate [translateParams]="{label: data.weeksLabel | translate}"
                       *ngIf="dialogForm.get('weeks').errors?.required">
                COMMON.ERRORS.REQ
            </mat-error>
            <mat-error translate [translateParams]="{label: data.weeksLabel | translate, minValue: 1, maxValue: 104}"
                       *ngIf="dialogForm.get('weeks').errors?.min">
                COMMON.ERRORS.MIN_VALUE
            </mat-error>
            <mat-error translate [translateParams]="{label: data.weeksLabel | translate, minValue: 1, maxValue: 104}"
                       *ngIf="dialogForm.get('weeks').errors?.max">
                COMMON.ERRORS.MAX_VALUE
            </mat-error>
        </mat-form-field>
    </form>
    <!-- Message -->
    <p>{{message | translate}}</p>
    <!-- Warning -->
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 rounded relative" role="alert"
         *ngIf="warning">
        <strong class="font-bold" translate>COMMON.WARNING</strong>:
        <span>{{warning | translate}}</span>
    </div>
</div>
<!-- Actions -->
<div mat-dialog-actions class="py-6" align="end">
    <!-- Cancel -->
    <button mat-stroked-button (click)="dialogRef.close(false)" class="mr-1 mb-2">
        <span translate>{{cancel || 'COMMON.CANCEL' | translate}}</span>
    </button>
    <!-- Confirm -->
    <button mat-raised-button class="mat-primary mb-2" [mat-dialog-close]="dialogForm.getRawValue()"
            [disabled]="dialogForm.invalid" *ngIf="data">
        <strong>{{confirm | translate}}</strong>
    </button>
    <button mat-raised-button class="mat-primary mb-2" (click)="dialogRef.close(true)" *ngIf="!data && confirm">
        {{confirm | translate}}
    </button>
</div>
