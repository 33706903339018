import {IconsMockApi} from 'app/data/mock/ui/icons'
import {NavigationMockApi} from 'app/data/mock/common/navigation'

/**
 * Mocked data services
 *
 * Since there is still no need to store navigation
 * and icons data on the server, we use Treo's mocked API
 */
export const mockDataServices = [
    IconsMockApi,
    NavigationMockApi,
]
