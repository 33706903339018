import {Injectable} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {ErrorDialogComponent} from 'app/shared/dialogs/error-dialog/error-dialog.component'

/**
 * @class       ErrorDialogService
 * @summary     Error dialog service
 *
 * @description Show errors to users in a dialog
 */
@Injectable()
export class ErrorDialogService {
    // Dialog reference
    dialogRef: MatDialogRef<ErrorDialogComponent>

    // Prevent dialog from opening twice
    opened: boolean = false

    /**
     * Constructor
     *
     * @param {MatDialog} dialog - Material dialog
     */
    constructor(private dialog: MatDialog) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open error dialog
     *
     * @param {string} message - Message to show in dialog
     */
    openDialog(message: string): void {
        // Don't open dialog if it is already open
        if (this.opened) return
        this.opened = true

        // Open dialog
        this.dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {message},
            maxWidth: 380,
            disableClose: true,
            panelClass: 'dialog-responsive'
        })

        // Close dialog
        this.dialogRef.afterClosed().subscribe(() => {
            this.dialogRef = null
            this.opened = false
        })
    }
}
