import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'
import {SharedModule} from 'app/shared/shared.module'
import {EmptyLayoutComponent} from 'app/layout/layouts/empty/empty.component'

/**
 * @class       EmptyLayoutModule
 * @description Load empty layout and shared modules
 */
@NgModule({
    declarations: [
        EmptyLayoutComponent
    ],
    imports: [
        RouterModule,
        SharedModule
    ],
    exports: [
        EmptyLayoutComponent
    ]
})
export class EmptyLayoutModule {
}
