import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {forkJoin, Observable} from 'rxjs'
import {map} from 'rxjs/operators'

/**
 * @class       InitialDataResolver
 * @implements  Resolve
 * @summary     Data resolver
 *
 * @description Load navigation from mocked API
 */
@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient - HTTP client
     */
    constructor(
        private _httpClient: HttpClient
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Load navigation data
     *
     * @private
     */
    private _loadNavigation(): Observable<any> {
        return this._httpClient.get('api/common/navigation')
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            // Navigation data
            this._loadNavigation()
        ]).pipe(
            map((data) => {
                return {
                    navigation: {
                        default: data[0].default,
                    }
                }
            })
        )
    }
}
