import {NgModule} from '@angular/core'
import {TreoDrawerModule} from '@treo/components/drawer'
import {LayoutComponent} from 'app/layout/layout.component'
import {EmptyLayoutModule} from 'app/layout/layouts/empty/empty.module'
import {ClassicLayoutModule} from 'app/layout/layouts/vertical/classic/classic.module'
import {SharedModule} from 'app/shared/shared.module'

const modules = [
    // Empty
    EmptyLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    TreoDrawerModule
]

/**
 * @class       LayoutModule
 * @description Load layout and shared modules
 */
@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        SharedModule,
        ...modules
    ],
    exports: [
        ...modules
    ]
})
export class LayoutModule {
}
