import {Component, Inject, OnInit} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

/**
 * Confirm dialog data
 */
export interface DialogData {
    dateLabel: string
    dateValue: string
    textLabel: string
    weeksLabel: string
}

/**
 * @class       ConfirmDialogComponent
 * @implements  OnInit
 * @summary     Confirm dialog component
 *
 * @description Show confirmation dialog to user
 *              Also used to confirm pharmacy actions such as
 *              Request qualification and requalification, validate and reject qualification, ban pharmacy
 */
@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    // Confirm dialog text
    public title: string
    public message: string
    public warning: string
    public confirm: string
    public cancel: string
    public minDate: any

    // Dialog form
    dialogForm: FormGroup

    // Defaults
    regex = {
        letter: /[a-zA-ZäöüÄÖÜß\s-]/,
        number: /[0-9\s+]/,
        letterAndNumber: /[a-zA-ZäöüÄÖÜß0-9\s-]/,
        letters: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß\\s-]*$',
        numbers: '^(?![\\s]+$)[0-9\\s+]*$',
        numbersWithoutSpaces: /^\d+$/,
        lettersAndNumbers: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß0-9\\s-]*$'
    }

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder - Form builder
     * @param {MatDialogRef<ConfirmDialogComponent>} dialogRef - Dialog reference
     * @param {DialogData} data - Dialog data to be injected
     */
    constructor(
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     *
     * Create form depending on the data injected
     * Data is currently injected only from pharmacy module
     * Otherwise show only dialog text
     */
    ngOnInit(): void {
        // Create form with text area and date picker
        if (this.data?.textLabel) {
            this.dialogForm = this._formBuilder.group({
                date: [{value: this.data?.dateValue || new Date(), disabled: false}, Validators.required],
                text: [null, [Validators.required, Validators.minLength(5)]],
            })
        } else if (this.data?.dateValue) { // Create form only with date picker
            this.dialogForm = this._formBuilder.group({
                date: [{value: this.data?.dateValue || new Date(), disabled: false}, Validators.required],
                weeks: [{value: 104, disabled: false}, [Validators.required, Validators.minLength(1),
                    Validators.maxLength(3), Validators.min(1), Validators.max(104)]],
            })
        }
        // Min date for date picker
        this.minDate = new Date()
    }


    /**
     * Allow input given keypress event
     *
     * @param event - Input event
     * @param {string} regex - Regex key to be used from the defaults
     */
    allowInput(event: any, regex: string = 'letterAndNumber'): boolean {
        const input = String.fromCharCode(event.keyCode)
        if (this.regex[regex].test(input)) {
            // Allow input
            return true
        } else {
            event.preventDefault()
            // Prevent input
            return false
        }
    }

}
