import {NgModule, ErrorHandler} from '@angular/core'
import {CommonModule} from '@angular/common'
import {GlobalErrorHandler} from 'app/core/errors/global-error-handler'
import {HttpErrorInterceptor} from 'app/core/errors/http-error.interceptor'
import {HTTP_INTERCEPTORS} from '@angular/common/http'

/**
 * @class       ErrorHandlerModule
 * @summary     Error handler module for global and HTTP errors
 *
 * @description Provide global and HTTP error handlers
 */
@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
    ]
})
export class ErrorHandlerModule {
}
