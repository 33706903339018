import {TreoNavigationItem} from '@treo/components/navigation'
import jwt_decode from 'jwt-decode'
import {TokenPayload} from 'app/shared/shared.types'
import {environment} from '../../../../../environments/environment'

/**
 * Check if current account has access to module (item.id)
 *
 * @param {TreoNavigationItem} item - Treo navigation item
 */
export function hasAccess(item: TreoNavigationItem) {
    // Load token payload from local storage
    const payload = jwt_decode<TokenPayload>(localStorage.getItem('accessToken'))

    // Check if the is payload acl has the module
    if (payload.acl.hasOwnProperty(item.id)){
        return payload.acl[item.id].read
    }

    return false
}

/**
 * Check if current account:
 *  - is a customer
 *  - is validated
 */
export function showShopLink() {
    // return true
    // Load token payload from local storage
    const payload = jwt_decode<TokenPayload>(localStorage.getItem('accessToken'))
    //
    // // Return true if it is a validated customer
    // SHOW BUTTON FOR ALL CUSTOMERS
    return (payload.group === 'customer') //&& payload.pharmacyIds
        || (payload.group === 'user' && payload.role !== 'user')
}

/**
 * Shop url with access token
 */
export function getShopUrl() {
    return environment.shopUrl + '/?token=' + localStorage.getItem('accessToken')
}

/**
 * Default navigation menu
 */
export const defaultNavigation: TreoNavigationItem[] = [
    {
        id: 'account',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'shop',
                title: 'Shop',
                type: 'basic',
                link: getShopUrl(),
                externalLink: true,
                icon: 'mat_outline:shopping_cart',
                hidden: (() => !showShopLink())
            },
            {
                id: 'pharmacy',
                title: 'COMMON.PHARMACIES',
                type: 'basic',
                link: '/pharmacy',
                icon: 'mat_outline:local_pharmacy',
                hidden: (item => !hasAccess(item))
            },
            {
                id: 'customer',
                title: 'COMMON.CUSTOMERS',
                type: 'basic',
                link: '/customer',
                icon: 'heroicons_outline:user-group',
                hidden: (item => !hasAccess(item))
            },
            {
                id: 'user',
                title: 'COMMON.USERS',
                type: 'basic',
                link: '/user',
                icon: 'heroicons_outline:users',
                hidden: (item => !hasAccess(item))
            },
            {
                id: 'account',
                title: 'COMMON.ACCOUNT',
                type: 'basic',
                link: '/account',
                icon: 'heroicons_outline:user-circle',
                hidden: (item => !hasAccess(item))
            },
        ]
    }
]
export const compactNavigation: TreoNavigationItem[] = []
export const futuristicNavigation: TreoNavigationItem[] = []
export const horizontalNavigation: TreoNavigationItem[] = []
