import {Component} from '@angular/core'
import {TranslateService} from '@ngx-translate/core'
import {SharedUtils} from "./shared/shared.utils";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "./shared/dialogs/confirm-dialog/confirm-dialog.component";

/**
 * @class       AppComponent
 * @summary     App component
 *
 * @description Main app component
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Constructor
     *
     * @param {TranslateService} translate - Translate service
     * @param {MatDialog} dialog - Material dialog
     */
    constructor(private translate: TranslateService,
                public dialog: MatDialog,
    ) {
        // Set language to use
        let lang = localStorage.getItem('lang')
        if (!lang) {
            // Use browser language
            // lang = translate.getBrowserLang()
            lang = 'de'
            localStorage.setItem('lang', lang)
        }
        translate.use(lang)

        // Show customer notice
        let notice = localStorage.getItem('notice')
        if (!notice) {
            const noticeDialog = this.dialog.open(ConfirmDialogComponent, {
                maxWidth: 380,
                panelClass: 'dialog-responsive'
            })
            // Set dialog data
            noticeDialog.componentInstance.title = 'Kundenhinweis'
            noticeDialog.componentInstance.message = 'Bitte beachten Sie, dass unser Shop nur mit einem gültigen DocCheck-Account oder einem Canify-Account eingesehen werden kann. Bestellungen können wir nur von Apotheken entgegennehmen, die sich entsprechend qualifiziert haben. Das sieht das Heilmittelwerbegesetz so vor. Vielen Dank für Ihr Verständnis.'
            noticeDialog.componentInstance.cancel = 'COMMON.CLOSE'
            localStorage.setItem('notice', 'true')
        }
    }
}
