<h1 mat-dialog-title translate>COMMON.ERROR_OCCURRED</h1>
<div mat-dialog-content>
    <p class="error-message mb-3">
        {{data?.message | translate}}
    </p>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="primary" class="mb-2">
        <span translate>COMMON.CLOSE</span>
    </button>
</div>
