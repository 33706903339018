import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core'
import {Router} from '@angular/router'
import {Subject} from 'rxjs'
import {TranslateService} from '@ngx-translate/core'

/**
 * @class       UserComponent
 * @implements  {OnInit, OnDestroy}
 * @summary     User layout component
 *
 * @description User options in layout
 *              Get and set language
 *              Sign out
 */
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {

    // Private
    private _unsubscribeAll: Subject<any>

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {Router} _router
     * @param _translate
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _translate: TranslateService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out'])
    }

    /**
     * Set language to use
     *
     * @param language
     */
    useLanguage(language: string): void {
        localStorage.setItem('lang', language)
        this._translate.use(language)
    }

    /**
     * Get current language
     */
    getLanguage(): string {
        return localStorage.getItem('lang')
    }
}
