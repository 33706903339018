import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router'
import {MarkdownModule} from 'ngx-markdown'
import {TreoModule} from '@treo'
import {TreoConfigModule} from '@treo/services/config'
import {TreoMockApiModule} from '@treo/lib/mock-api'
import {CoreModule} from 'app/core/core.module'
import {appConfig} from 'app/core/config/app.config'
import {mockDataServices} from 'app/data/mock'
import {LayoutModule} from 'app/layout/layout.module'
import {appRoutes} from 'app/app.routing'
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client'
import {LoadingBarModule} from '@ngx-loading-bar/core'
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'
import {HttpClient, HttpClientModule} from '@angular/common/http'
import {MAT_DATE_LOCALE} from '@angular/material/core'
import {registerLocaleData} from '@angular/common'
import localeDe from '@angular/common/locales/de'
import {AppComponent} from 'app/app.component'

registerLocaleData(localeDe)

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}

/**
 * @class       AppModule
 * @summary     App module
 *
 * @description Main app module
 *              Load app routes and common dependencies
 *              Load translations
 */
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Progress bar
        MatProgressBarModule,
        MatProgressSpinnerModule,
        LoadingBarHttpClientModule,
        LoadingBarModule,

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // Translation
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'de'
        }),

        // 3rd party modules
        MarkdownModule.forRoot({})
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [{
        provide: MAT_DATE_LOCALE, useValue:  'de-DE'
    }]
})
export class AppModule {
}

// Translate loader
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
