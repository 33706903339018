import {ErrorHandler, Injectable, NgZone} from '@angular/core'
import {ErrorDialogService} from 'app/shared/dialogs/error-dialog/error-dialog.service'

/**
 * @class       GlobalErrorHandler
 * @implements  ErrorHandler
 * @summary     Error handler for global errors
 *
 * @description Handle global errors and open error dialog
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    /**
     * Constructor
     *
     * @param {ErrorDialogService} errorDialogService - Error dialog service for displaying errors
     * @param {NgZone} zone
     */
    constructor(private errorDialogService: ErrorDialogService, private zone: NgZone) {
    }

    /**
     * Handle error
     *
     * @param {Error} error
     */
    handleError(error: Error) {
        this.zone.run(() => {
            // Todo: fix these errors (they dont impact on functionality)
            // Do not show these errors to users
            if (
                error.message.includes('unsafe') // url unsafe error (PDF dialog)
                || error.message.includes('ExpressionChangedAfterItHasBeenChecked') // <mat-select> translation error
            ) return

            // Show error dialog
            this.errorDialogService.openDialog(error.message || 'Undefined client error')
        })
    }
}
