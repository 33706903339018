import {NgModule} from '@angular/core'
import {HttpClientModule} from '@angular/common/http'
import {RouterModule} from '@angular/router'
import {TreoNavigationModule} from '@treo/components/navigation'
import {UserModule} from 'app/layout/common/user/user.module'
import {SharedModule} from 'app/shared/shared.module'
import {ClassicLayoutComponent} from 'app/layout/layouts/vertical/classic/classic.component'

/**
 * @class       EmptyLayoutModule
 * @description Load classic layout and shared modules
 */
@NgModule({
    declarations: [
        ClassicLayoutComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        TreoNavigationModule,
        UserModule,
        SharedModule
    ],
    exports: [
        ClassicLayoutComponent
    ]
})
export class ClassicLayoutModule {
}
