<!-- Language switch -->
<!--<button class="mr-3 underline" (click)="useLanguage('en')" *ngIf="getLanguage() !== 'en'">en</button>
<button class="mr-3 underline" (click)="useLanguage('de')" *ngIf="getLanguage() !== 'de'">de</button>-->
<!-- User button -->
<button class="icon-button" mat-icon-button [matMenuTriggerFor]="userActions">
    <mat-icon [svgIcon]="'more_vert'"></mat-icon>
</button>
<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item [routerLink]="['/account']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span translate>COMMON.ACCOUNT</span>
    </button>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span translate>COMMON.SIGN_OUT</span>
    </button>
</mat-menu>
