import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { AuthService } from 'app/core/auth/auth.service'
import { TranslateService } from '@ngx-translate/core'
import { MatDialogConfig } from '@angular/material/dialog/dialog-config'
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component'

/**
 * @class       SharedUtils
 * @summary     Shared utils
 *
 * @description General shared configs, methods and services
 *              Shared form group structure
 *              Open dialog and snack bar
 *              Auth and translate services
 */
@Injectable()
export class SharedUtils {
    // Confirm dialog
    confirmDialog: MatDialogRef<ConfirmDialogComponent>

    // Configs
    snackBarConfig: MatSnackBarConfig = {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 4000,
        panelClass: ['mat-toolbar', 'bg-primary', 'text-on-primary']
    }

    // Defaults
    regex = {
        letter: /[a-zA-ZäöüÄÖÜß\s-]/,
        number: /[0-9\s+]/,
        letterAndNumber: /[a-zA-ZäöüÄÖÜß0-9\s-]/,
        letters: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß\\s-]*$',
        numbers: '^(?![\\s]+$)[0-9\\s+]*$',
        numbersWithoutSpaces: /^\d+$/,
        lettersAndNumbers: '^(?![\\s.]+$)[a-zA-ZäöüÄÖÜß0-9\\s-]*$'
    }

    // Form controls configs
    company: any
    userCompany: any
    address: any
    contactPerson: any
    personalData: any
    userPersonalData: any

    /**
     * Constructor
     *
     * @param {FormBuilder} formBuilder - Form builder
     * @param {MatDialog} dialog - Material dialog
     * @param {MatSnackBar} snackBar - Material snack bar
     * @param {AuthService} auth - Auth service
     * @param {TranslateService} translate - Translate service
     */
    constructor(
        public formBuilder: FormBuilder,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public auth: AuthService,
        public translate: TranslateService,
    ) {
        // Default company form controls
        this.company = {
            company: [null, [Validators.required, Validators.minLength(4), Validators.pattern(this.regex.lettersAndNumbers)]],
            email: [null, [Validators.required, Validators.email]],
            phone: [null, [Validators.required, Validators.minLength(9), Validators.pattern(this.regex.numbers)]],
            fax: [null, [Validators.minLength(9), Validators.pattern(this.regex.numbers)]],
            // btmNumber: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern(this.regex.numbers)]],
        }

        // Default company form controls
        this.userCompany = {
            company: [null, [Validators.required, Validators.minLength(4), Validators.pattern(this.regex.lettersAndNumbers)]],
            email: [null, [Validators.required, Validators.email]],
            phone: [null, [Validators.required, Validators.minLength(9), Validators.pattern(this.regex.numbers)]],
            fax: [null, [Validators.minLength(9), Validators.pattern(this.regex.numbers)]]
        }

        // Default address form controls
        this.address = {
            street: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.lettersAndNumbers)]],
            number: [null, [Validators.required, Validators.pattern(this.regex.lettersAndNumbers)]],
            postalCode: [null, [Validators.required, Validators.minLength(4), Validators.pattern(this.regex.lettersAndNumbers)]],
            city: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.letters)]],
            state: [null, Validators.required],
            country: [null, Validators.required],
        }

        // Default contact person form controls
        this.contactPerson = {
            title: [null],
            salutation: [null, Validators.required],
            firstName: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.letters)]],
            lastName: [null, [Validators.required, Validators.minLength(2), Validators.pattern(this.regex.letters)]],
        }

        // Default personal data form controls
        this.personalData = {
            ...this.company,
            address: this.formBuilder.group(this.address),
            contactPerson: this.formBuilder.group(this.contactPerson),
        }

        // Default personal data form controls
        this.userPersonalData = {
            ...this.userCompany,
            address: this.formBuilder.group(this.address),
            contactPerson: this.formBuilder.group(this.contactPerson),
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Allow input given keypress event
     *
     * @param event - Input event
     * @param {string} regex - Regex key to be used from the defaults
     */
    allowInput(event: any, regex: string = 'letterAndNumber'): boolean {
        const input = String.fromCharCode(event.keyCode)
        if (this.regex[regex].test(input)) {
            // Allow input
            return true
        } else {
            event.preventDefault()
            // Prevent input
            return false
        }
    }

    /**
     * Open a confirm dialog
     *
     * @param {string} action - Action to be executed (for translation purposes)
     * @param {MatDialogConfig} config - Material dialog config
     * @param {boolean} warning - Show warning
     */
    openConfirmDialog(action: string, config: MatDialogConfig = {
        maxWidth: 380,
        panelClass: 'dialog-responsive'
    }, warning?: boolean): any {
        action = action.toUpperCase()
        // Open dialog
        this.confirmDialog = this.dialog.open(ConfirmDialogComponent, config)
        // Set dialog data
        this.confirmDialog.componentInstance.title = action + '.TITLE'
        this.confirmDialog.componentInstance.message = action + '.MESSAGE'
        this.confirmDialog.componentInstance.confirm = action + '.CONFIRM'
        this.confirmDialog.componentInstance.allowInput = this.allowInput
        if (warning) {
            this.confirmDialog.componentInstance.warning = action + '.WARNING'
        }
    }

    /**
     * Open snack bar
     *
     * @param {string} message - Message to show in snack bar
     * @param {string} action - Close snack bar text
     */
    openSnackBar(message: string, action?: string): void {
        this.translate.get(message).subscribe((msg: string) => {
            this.snackBar.open(msg, action, this.snackBarConfig)
        })
    }
}
